@use './config/' as *;
@forward './main.scss';
@forward './events/fourstar-shoswcase';
@forward "./fixtures/results";
@forward "./standings/common-ranking-results";

.waf-fixtures{
    background-color: var(--neutral-0);
    .tab{
        &-section{
            @extend %bg-transparent;
            @extend %p-4;
            @include border(1, neutral-20);
            .tabs{
                margin-bottom: var(--space-14);
            }
        }
    }
    .table-option-list{
        background: linear-gradient(270deg, hsla(var(--hsl-gradient1-0)/0) -6.17%, hsla(var(--hsl-gradient1-0)/0.5) 100%);
    }
    .table-responsive{
        + .table-responsive{
            @extend %mt-14;
        }
    }
}

@include mq(col-tablet){
    .waf-fixtures{
        .tab{
            &-section{
                padding: var(--space-6) var(--space-10) var(--space-10);
                .tabs{
                    margin-bottom: var(--space-6);
                    margin-inline: var(--space-10-neg);
                }
            }
        }
        .table-responsive{
            + .table-responsive{
                margin-top: var(--space-10);
            }
        }
    }
}